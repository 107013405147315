import React, {FC, useContext, useEffect, useRef, useState} from "react";
import {
	Row,
	Container,
	Text,
	Col,
	iconsTypes,
	Button,
	DropdownMulti,
	ComplexFilter,
	ComplexFilterType,
	ComplexFilterChangeType, onChangeComplexFilterData, Toggle
} from "lavaa";
import { BackButton } from "../../Common/BackButton/BackButton.component";
import css from "./PatientListDesktop.module.scss";
import {DataTable} from "../../DataTable/DataTable.com";
import {usePriorities} from "../../../Hooks/usePriorities";
import {useAccount} from "../../../Hooks/UseAccount";
import {
	handleComplexFilterChanged,
	handleTableAction,
	prepareFilter,
	resetComplexFilterOnChangeQuickFilter,
	resetQuickFiltersOnChangeComplexFilter
} from "./utils";
import {PatientListTypeFilterType} from "./PatientList.type";
import {ReportLabel} from "../../PatientPage/ReportLabel/ReportLabel.component";
import {DiagnosisRisk} from "../DiagnosisRisk/DiagnosisRisk.com";
import {HeadersDataType} from "./Results.data";
import {MethodsType} from "../../DataTable/DataTable.types";
import {noop} from "../../../Tools/Noop";
import {ModalsCtx} from "../../../Context/Modals.context";
import {useLocalStorage} from "../../../Hooks/UseLocalStorage";
import {ReportClientInfo} from "../../Custom/Modals/ReportClientInfo/ReportClientInfo.com";
import {ServerSearchFilter} from "../../DataTable/Filters/ServerSearchFilter/ServerSearchFilter.com";
import {getRandom} from "../../../Tools";

interface IProps {
}

// Patients List
const PatientsListDesktop: FC<IProps> = () => {

	// Hooks
	const { HMSProjectId, account } = useAccount();
	const {fetchClientsHealthPriorities, clientsPriorities, exportLoading, fetchExcelPrioritiesData} = usePriorities();
	const dataTableMethodsRef = useRef<MethodsType | null>(null);
	const {setPatientLabResultModalActive, setPatientLabResultModalData, setReportClientInfoModalActive, setReportClientInfoId} = useContext(ModalsCtx);

	const [filteredHeaders, setFilteredHeaders] = useState<string[]>([]);
	const [showModules, setShowModules ] = useLocalStorage<boolean>("patients-list-show-modules", false);
	const [headers, setHeaders] = useState<HeadersDataType[]>([]);
	const [reset, setReset] = useState(0);
	const [rows, setRows] = useState([]);
	const [totalPages, setTotalPages] = useState(1);

	const [filteredHeadersLastUpdate, setFilteredHeadersLastUpdate] = useState(0);
	const [complexFilterData, setComplexFilterData] = useState<ComplexFilterType>([]);
	const [reload, setReload] = useState(0);
	const initRef = useRef(true);
	const complexFilterChangeMarker = useRef<boolean>(false);

	const dataHeadersRef = useRef<any>();
	const columnStateRef = useRef<any[]>()
	const columnCountRef = useRef<number>(0);
	const dataTableIdRef = useRef<number>(getRandom(0, 100000));

	// Table Filter
	const [filter, setFilter] = useState<PatientListTypeFilterType>({
		page: 1,
		pageSize: 10,
		filterBy: [],
		sorting: {
			sortedByField: '',
			isAscending: true,
			sortingType: 0, // 0 - any; 1 - string; 2 - number; 999 - other;
		},
	});

	const filterRef = useRef(filter);
	const filteredHeadersRef = useRef(filteredHeaders);

	// Handle Action
	const handleFilterAction = (actionType: string, data: any) => {
		// Prevent multiple complex filter change events
		if(complexFilterChangeMarker.current){
			return;
		}

		const newFilter = handleTableAction(actionType, data, filterRef.current, filteredHeadersRef.current);

		if(!newFilter) return;
		setFilter(newFilter);
	}

	const [columnModel, setColumnModel] = useState([
		{
			field: 'patientidfullname', headerName: 'Patient', pinned: true, width: 250,
			renderer: ['PatientCellRenderer', 'ClientDetailsCellRenderer'], setClientDetailsId: (clientId: string) => {openClientInfo(clientId)}, comparator: noop,
			floatingFilterComponent: ServerSearchFilter, floatingFilterComponentParams: {
				onChange: handleFilterAction,
				tableId: dataTableIdRef.current
			}, suppressMenu: true
		},
	]);

	useEffect(() => {
		fetchClientsHealthPriorities(HMSProjectId, filter.sorting, [...filter.filterBy], filter.page, filter.pageSize);
		filterRef.current = filter;
	}, [filter]);

	useEffect(() => {
		filteredHeadersRef.current = filteredHeaders;
	}, [filteredHeaders]);

	useEffect(() => {
		if(!initRef.current){
			initRef.current = true;
			onResetFilter();
		}
	}, [HMSProjectId]);

	useEffect(() => {
		if(!initRef.current) {
			handleTableDataChanged(clientsPriorities, true);
		}
	}, [showModules]);

	// Watch Report Data
	useEffect(() => {
		if (clientsPriorities?.lastUpdated) {
			handleTableDataChanged(clientsPriorities);
		}
	}, [clientsPriorities?.lastUpdated]);

	// Handle Table Data Changed
	const handleTableDataChanged = (data: any = {}, updateHeader = false) => {
		const { rows = [], totalPages = 1  } = data;
		const localHeaders = data.headers || [];

		// Initialize Headers
		if(initRef.current || updateHeader){
			// Save Removed Columns
			const removedColumns: string[] = [];
			if(headers.length > 0){
				headers.forEach((header: any) => {
					const exists = filteredHeaders.some((col: any) => col.columnName === header.columnName);
					if(!exists) removedColumns.push(header.columnName);
				});
			}

			dataHeadersRef.current = [...localHeaders];

			const columnsWithoutFilter = ["DateOfBirth"];

			// Count Module Icons Width
			const modulesIcons: string[] = [];
			if(!showModules){
				localHeaders.filter((row: any) => row.dataType === 'module').forEach((header: any) => {
					const icon = header.icon || "stack";
					if(!modulesIcons.includes(icon)) {
						modulesIcons.push(icon);
					}
				});
			}

			const totalModules = modulesIcons.length;
			const moduleIconsWidth = totalModules * 36 + 40;

			// Prepare Headers
			const indexedHeaders = localHeaders
				.filter((row: any) => row.isShownInAppReport && (row.dataType !== 'module' || showModules) && row.columnName !== 'RiskLevel')
				.map((header: any) => {
					const isModule = header.dataType === 'module';
					const hasFilter = !isModule && !columnsWithoutFilter.includes(header.columnName);

					return {
						columnName: header.columnName,
						field: header.columnName.toLowerCase(),
						headerName: header.displayName,
						index: header.index,
						renderer: isModule ? moduleRenderer : undefined,
						type: isModule ? 'module' : 'info',
						hasFilter: hasFilter,
					};
				});

			indexedHeaders.forEach((header: any) => {
				if(columnStateRef.current){
					const index = columnStateRef.current.findIndex((col: any) => col.colId === header.field) ?? -1;
					header.index = index >= 0 ? index : header.index + 100;
				}
			});

			// Prepare Column Model
			const updatedColumnModel = indexedHeaders.map((header: any) => {
				const { field, headerName, renderer } = header;
				const colModel = columnModel.find((col: any) => col.field === field);
				return colModel
					? {
						...colModel,
						headerTooltip: headerName
					} : {
						field,
						renderer,
						headerTooltip: headerName
					};
			});

			// Add Diagnosis & Risk Column if not exists
			if(!showModules){
				const hasDiagnosis = indexedHeaders.some((header: any) => header.field === "risklevel");

				if(!hasDiagnosis){

					updatedColumnModel.push({
						field: 'risklevel',
						headerTooltip: 'Diagnosis & Risk',
						renderer: diagnosisRenderer,
						minWidth: moduleIconsWidth,
					});

					indexedHeaders.push({
						columnName: "RiskLevel",
						field: 'risklevel',
						headerName: 'Diagnosis & Risk',
						index: 1000,
						type: 'diagnosis',
						hasFilter: false,
					})
				}
			}

			indexedHeaders.sort((a: any, b: any) => a.index - b.index);



			// Filter Removed Columns
			const newFilteredHeaders = indexedHeaders.filter((header: any) => !removedColumns.includes(header.columnName));

			columnCountRef.current = indexedHeaders.length;

			setHeaders(indexedHeaders);
			setColumnModel(updatedColumnModel);
			setFilteredHeaders(newFilteredHeaders);

			if(initRef.current){
				setComplexFilterData(prepareFilter(localHeaders));
			}

			setReset(performance.now());

			columnStateRef.current = indexedHeaders.map((header: any) => {return {colId: header.field}});
			initRef.current = false;

			if(updateHeader){
				setFilteredHeadersLastUpdate(performance.now());
			}
		}

		setTotalPages(totalPages);

		const indexedRows = rows.map((row: any) => {
			const results: any = {};

			if(showModules){
				row.results.map((result: any) => {
					results[result.moduleUID] = {
						...result,
						patient: {
							name: row.fullname || undefined,
						}
					};
				})
			}

			return {
				...row,
				...results,
				// EMRLink: `https://app.elationemr.com/patient/${row.sourceclientid}`
			}
		});

		setRows(indexedRows);
	};

	// Handle Action
	const handleAction = (actionType: string, data: any) => {
		// Prevent multiple complex filter change events
		if(complexFilterChangeMarker.current){
			return;
		}

		const newFilter = handleTableAction(actionType, data, filter, filteredHeaders);
		const newComplexFilter = resetComplexFilterOnChangeQuickFilter(actionType, data, headers, complexFilterData);

		if(!newFilter) return;
		setFilter(newFilter);
		if(newComplexFilter) setComplexFilterData(newComplexFilter);
	};

	// Handle Toggle Column
	const handleToggleColumn = (data: any) => {
		console.log('Toggle Column', data, columnStateRef.current);
		const indexedData = data.map((item: any) => {
			const index = columnStateRef.current?.findIndex((col: any) => col.colId === item.field) ?? -1;
			return {
				...item,
				index: index >= 0 ? index : item.index + 100
			};
		})

		indexedData.sort((a: any, b: any) => a.index - b.index);

		columnCountRef.current = indexedData.length;
		setFilteredHeaders(indexedData);
		setFilteredHeadersLastUpdate(performance.now());
	};

	function onExport() {
		if (exportLoading == false) {
			fetchExcelPrioritiesData(HMSProjectId, account.access_token, filter.filterBy);
		}
	}

	function onColumnMoved(data: any) {
		const columnState = data.api.getColumnState();

		// Skip if the column state is not fully loaded
		if(columnCountRef.current != columnState.length) return;

		columnStateRef.current = columnState;
	}

	function onComplexFilterChange(data: ComplexFilterChangeType){
		dataTableMethodsRef.current?.changePage(1);
		const newComplexFilterData = onChangeComplexFilterData(complexFilterData, data);
		const newFilter = handleComplexFilterChanged(data, filter, dataHeadersRef.current);

		if(newFilter){
			// Prevent multiple change events
			complexFilterChangeMarker.current = true;
			setComplexFilterData(newComplexFilterData);

			// Reset Data Table Filter
			resetQuickFiltersOnChangeComplexFilter(data, dataTableMethodsRef.current);

			setTimeout(() => {
				setFilter(newFilter);
				setReload((prev) => prev + 1);
				complexFilterChangeMarker.current = false;
			}, 1000);
		}
	}

	// On Report Label Click
	const onReportLabelClick = (e: Event, moduleName: string, labResult: any, client: any) => {
		e.stopPropagation();

		setPatientLabResultModalData({moduleName, labResult, client});
		setPatientLabResultModalActive(true);
	};

	function moduleRenderer(data: any) {
		if(!data || !data.hasOwnProperty("value")) return null;
		const result = data.value;

		const moduleName = result.moduleName;
		const patient = {
			name: data.data?.fullname
		};

		return (
			<ReportLabel
				type="button"
				textColor={result.textColor}
				bgColor={result.bgColor}
				onClick={(e: Event) => onReportLabelClick(e, moduleName, result, patient)}
			>
				{result.resultGroupName}
			</ReportLabel>
		)
	}

	function diagnosisRenderer(data: any) {
		if(!data || !data.hasOwnProperty("data") || !data.data.hasOwnProperty("results")) return null;
		const result = data.data.results;

		return <DiagnosisRisk results={result} headers={dataHeadersRef.current}/>
	}

	function changeModuleView(state: boolean) {
		setShowModules(state);
	}

	function onResetFilter() {
		complexFilterChangeMarker.current = true;

		const event = new CustomEvent('ResetDataTableFilter', {
			detail: {tableId: dataTableIdRef.current}
		});
		window.dispatchEvent(event);

		if(dataTableMethodsRef.current) {
			dataTableMethodsRef.current.resetFilter();
		}

		setTimeout(() => {
			setComplexFilterData(prepareFilter(headers));
			setFilter((prev) => ({
				...prev,
				filterBy: []
			}));
			setReload((prev) => prev + 1);
			complexFilterChangeMarker.current = false;
		}, 500)
	}


	function openClientInfo(clientId: string){
		setReportClientInfoId(clientId);
		setReportClientInfoModalActive(true);
	}

	return (
		<Container paddingLeft="1rem" paddingRight="1rem" paddingTop="2rem" paddingBottom="1.5rem" grow="1" className={css.PatientList}>

			{/* Title */}
			<Row paddingBottom="2rem" alignitems="center" justifycontent="space-between">
				<Row alignitems="center">
					{/* Back Button */}
					<Row paddingRight="1.5rem">
						<BackButton link={"/"}/>
					</Row>

					{/* Page Title */}
					<Row grow="1" paddingRight="2rem">
						<Text text="Health Priority" size="x6" bold={true} className={css.Title} ellipsis={true}/>
					</Row>
				</Row>

				<Row>
					<Button icon={iconsTypes.excel} onClick={onExport}>
						Export
					</Button>
				</Row>
			</Row>

			<Col className={css.PatientListBlock} grow="1">
				<Row
					alignitems="center" justifycontent="space-between"
					paddingRight="1rem" paddingTop="1rem" paddingBottom="1rem" paddingLeft="1rem"
				>
					<Row paddingRight="1rem">
						<Row paddingRight="1rem">
							<DropdownMulti
								data={headers}
								displayField="headerName"
								selectField="field"
								placeholder="Shown Columns"
								initialSelected={filteredHeaders}
								reset={reset}
								type="button"
								icon={iconsTypes.columnView}
								onSelect={handleToggleColumn}
							/>
						</Row>
						<Row paddingRight="1rem">
							<ComplexFilter label="Filter" size="medium" icon={iconsTypes.filter} data={complexFilterData} onChange={onComplexFilterChange}/>
						</Row>
						<Row paddingRight="1rem" alignitems="center">
							<Button variant="text" icon={iconsTypes.refresh} onClick={onResetFilter} text="Reset All"/>
						</Row>
					</Row>
					<Row>
						<Toggle
							label="Modules View"
							labelPosition="right"
							size="default"
							onToggle={changeModuleView}
							active={showModules}
						/>
					</Row>
				</Row>
				<Col grow="1">
					<DataTable
						headers={filteredHeaders}
						rows={rows}
						columnModel={columnModel}
						minColumnWidth={150}
						totalPages={totalPages}
						onAction={handleAction}
						useServer={true}
						filteredHeadersLastUpdate={filteredHeadersLastUpdate}
						hideFloatingFilter={true}
						reload={reload}
						methods={dataTableMethodsRef}
						onColumnMoved={onColumnMoved}
					/>
				</Col>
			</Col>
			<ReportClientInfo/>
		</Container>
	)
};

export {PatientsListDesktop};
