import { receiveProjectAddCallback } from "../Slices/Projects/Projects.callbacks";
import {
    receiveLoginCallback,
    receiveRefreshTokenCallback,
    receiveUserInfoCallback,
    receiveUserProjectsActionCallback
} from '../Slices/Account/Account.callbacks';
import { receiveRuleCloneCallback } from "../Slices/Rules/Rules.callbacks";
import { receivePublishRulesetCallback } from "../Slices/Rulesets/Rulesets.callbacks";
import { receivePublishModuleCallback } from "../Slices/Modules/Modules.callbacks";
import {
    receiveChangeClientGuidelineStatusCallback,
    receiveChangeGuidelineStatusCallback,
    receiveDemoRunCallback
} from "../Slices/Demo/Demo.callbacks";
import {
    receiveAddOpenQuestionnaireAnswerCallback,
    receiveAddQuestionnaireAnswerCallback, receiveOpenQuestionnaireCallback
} from "../Slices/Questionnaire/Questionnaire.callbacks";
import { receiveProjectStatisticsActionCallback } from "../Slices/Reports/Reports.callbacks";

export const Messages = {
    CLIENT: { // Requests

        // Projects
        'Projects/getProjectsAction': { func: 'Rules/GetProjects', preprocess: { stringify: ['jsonLogic'] } },
        'Projects/getProjectAction': { func: 'Rules/GetProject' },
        'Projects/addProjectAction': { func: 'Rules/AddProject' },
        'Projects/updateProjectAction': { func: 'Rules/UpdateProject', preprocess: { stringify: ['jsonLogic'] } },
        'Projects/publishProjectAction': { func: 'Rules/PublishProject' },
        'Projects/runProjectAction': { func: 'Execution/RunProject' },
        'Projects/deleteProjectAction': { func: 'Rules/DeleteProject' },

        // Modules
        'Modules/getModulesAction': { func: 'Rules/GetModules' },
        'Modules/getModuleTemplateAction': { func: 'Rules/GetModuleTemplate' },
        'Modules/getModuleInfoAction': { func: 'Rules/GetModuleInfo' },
        'Modules/getModuleAction': { func: 'Rules/GetModule' },
        'Modules/getModuleAndUpdateAction': { func: 'Rules/GetModuleAndUpdate' },
        'Modules/addModuleAction': { func: 'Rules/AddModule', preprocess: { stringify: ['jsonLogic'] } },
        'Modules/updateModuleAction': { func: 'Rules/UpdateModule', preprocess: { stringify: ['jsonLogic'] } },
        'Modules/publishModuleAction': { func: 'Rules/PublishModule' },
        'Modules/deleteModuleAction': { func: 'Rules/DeleteModule' },
        'Modules/saveModuleReportAction': { func: 'Rules/ModuleConfigurationUpdate' },
        'Modules/fetchModuleReportAction': { func: 'Rules/ModuleConfigurations' },

        // Rulesets
        'Rulesets/getRulesetsAction': { func: 'Rules/GetRulesets' },
        'Rulesets/getRulesetAction': { func: 'Rules/GetRuleset' },
        'Rulesets/addRulesetAction': { func: 'Rules/AddRuleset', preprocess: { stringify: ['jsonLogic'] } },
        'Rulesets/updateRulesetAction': { func: 'Rules/UpdateRuleset', preprocess: { stringify: ['jsonLogic'] } },
        'Rulesets/publishRulesetAction': { func: 'Rules/PublishRuleset' },
        'Rulesets/deleteRulesetAction': { func: 'DeleteRuleset' },

        // Rules
        'Rules/getRulesByParentAction': { func: 'Rules/GetRulesByParent' },
        'Rules/cloneRuleAction': { func: 'Rules/CloneRule' },
        'Rules/getRulesAction': { func: 'Rules/GetRules' },
        'Rules/getRuleAction': { func: 'Rules/GetRule' },
        'Rules/addRuleAction': { func: 'Rules/AddRule', preprocess: { stringify: ['jsonLogic'] } },
        'Rules/updateRuleAction': { func: 'Rules/UpdateRule', preprocess: { stringify: ['jsonLogic'] } },
        'Rules/deleteRuleAction': { func: 'DeleteRule' },

        // Types
        'Types/getTypesAction': { func: 'Rules/GetTypes' },
        'Types/getTypeAction': { func: 'Rules/GetType' },

        // Clients
        'Clients/getClientSchemeAction': { func: 'Clients/GetClientScheme' },
        'Clients/getGetClientICD10CodesAction': { func: 'Clients/GetClientICD10Codes' },

        // Account
        'Account/loginAccountAction': { func: 'Auth/Login' },
        'Account/getAccountAction': { func: 'Auth/UserInfo' },
        'Account/refreshTokenAction': { func: 'Auth/RefreshToken' },
        'Account/registrationAccountAction': { func: 'RegistrationAccount' }, // Temporary
        'Account/getUserProjects': { func: 'Rules/GetUserProjects' },

        // Demo
        'Demo/getDemoModulesAction': { func: 'Demo/GetDemoRulesData' },
        'Demo/getDemoClientsAction': { func: 'Demo/GetDemoClients' },
        'Demo/getDemoClientAction': { func: 'Demo/GetDemoClient' },
        'Demo/getDemoClientGuidelinesAction': { func: 'Demo/GetDemoClientGuidelines' },
        'Demo/changeClientGuidelineStatusAction': { func: 'Demo/ChangeClientGuidelineStatus' },
        'Demo/changeGuidelineStatusAction': { func: 'Demo/ChangeGuidelineStatus' },
        'Demo/runDemoClientsAction': { func: 'Demo/RunDemoClients' },
        'Demo/runDemoClientAction': { func: 'Demo/RunDemoClient' },

        // ClinicalResearch
        'ClinicalResearch/getClinicalResearchesAction': { func: 'ClinicalResearch/GetClinicalResearches' },
        'ClinicalResearch/updateClinicalResearchStatusAction': { func: 'ClinicalResearch/UpdateClinicalResearchStatus' },

        // Help Center
        'HelpCenter/getHelpCenterDataAction': { func: 'Help/GetHelp' },

        // Questionnaire
        'Questionnaire/getQuestionnaireAction': { func: 'Questionnaire/GetQuestionnaire' },
        'Questionnaire/getQuestionnaireStatisticsAction': { func: 'Questionnaire/GetQuestionnaireStatistics' },
        'Questionnaire/addQuestionnaireAnswersAction': { func: 'Questionnaire/AddQuestionnaireAnswers' },
        'Questionnaire/getOpenQuestionnaireAction': { func: 'Questionnaire/GetOpenQuestionnaire' },
        'Questionnaire/addOpenQuestionnaireAnswersAction': { func: 'Questionnaire/AddOpenQuestionnaireAnswers' },

        // Statistics
        'Statistics/getProjectStatisticsAction': { func: 'Statistics/GetProjectStatistics' },

        // Reports
        'Reports/getReportsAction': { func: 'Reports/GetReportResults' },
        'Reports/getReportsWithPagerAction': { func: 'Reports/GetReportResultsWithPager' },
        'Reports/runReportsAction': { func: 'Reports/RunReportAction' },

        // Clients
        'Reports/getClientDetailsByIdAction': { func: 'Clients/GetClientDetailsByID' },

        // Priorities
        'Priorities/getClientsHealthPrioritiesAction': { func: 'Priorities/GetClientsHealthPriorities' }
    },

    SERVER: { // Responses

        // Projects
        ProjectsAnswer: { action: 'Projects/receiveProjectsAction' },
        ProjectAnswer: { action: 'Projects/receiveProjectAction' },
        ProjectAddAnswer: { action: 'Projects/receiveProjectAddAction', preprocess: { callback: [receiveProjectAddCallback] } },
        ProjectUpdateAnswer: { action: 'Projects/receiveUpdateProjectAction' },
        ProjectPublishAnswer: { action: 'Projects/receivePublishProjectAction', preprocess: { jsonParse: false } },
        ProjectDeleteAnswer: { action: 'Projects/receiveDeleteProjectAction', preprocess: { jsonParse: false } },
        // ProjectExecutionStatusAnswer: { action: 'Projects/receiveProjectRunAnswer', preprocess: { callback: [receiveProjectRunCallback] } },

        // Modules
        ModulesAnswer: { action: 'Modules/receiveModulesAction' },
        ModuleTemplateAnswer: { action: 'Modules/receiveModuleTemplateAction' },
        ModuleInfoAnswer: { action: 'Modules/receiveModuleInfoAction' },
        ModuleAnswer: { action: 'Modules/receiveModuleAction' },
        ModuleAddAnswer: { action: 'Modules/receiveAddModuleAction' },
        ModuleUpdateAnswer: { action: 'Modules/receiveUpdateModuleAction' },
        ModulePublishAnswer: { action: 'Modules/receivePublishModuleAction', preprocess: { jsonParse: false, callback: [receivePublishModuleCallback] } },
        ModuleDeleteAnswer: { action: 'Modules/receiveDeleteModuleAction', preprocess: { jsonParse: false } },
        ModuleConfigurationUpdateAnswer: { action: 'Modules/receiveSaveModuleReportAction', preprocess: { jsonParse: false }},
        ModuleConfigurationsAnswer: { action: 'Modules/receiveModuleReportAction', preprocess: { jsonParse: true }},

        // Rulesets
        RulesetsAnswer: { action: 'Rulesets/receiveRulesetsAction' },
        RulesetAnswer: { action: 'Rulesets/receiveRulesetAction' },
        RulesetAddAnswer: { action: 'Rulesets/receiveAddRulesetAction' },
        RulesetUpdateAnswer: { action: 'Rulesets/receiveUpdateRulesetAction', preprocess: { jsonParse: false } },
        RulesetPublishAnswer: { action: 'Rulesets/receivePublishRulesetAction', preprocess: { jsonParse: false, callback: [receivePublishRulesetCallback] } },
        RulesetDeleteAnswer: { action: 'Rulesets/receiveDeleteRulesetAction' },

        // Rules
        RulesByParentAnswer: { action: 'Rules/receiveRulesByParentAction' },
        RuleCloneAnswer: { action: 'Rules/receiveRuleCloneAction', preprocess: { callback: [receiveRuleCloneCallback] } },
        RulesAnswer: { action: 'Rules/receiveRulesAction' },
        RuleAnswer: { action: 'Rules/receiveRuleAction' },
        RuleAddAnswer: { action: 'Rules/receiveAddRuleAction' },
        RuleUpdateAnswer: { action: 'Rules/receiveUpdateRuleAction', preprocess: { jsonParse: false }  },
        RuleDeleteAnswer: { action: 'Rules/receiveDeleteRuleAction' },

        // Types
        TypesAnswer: { action: 'Types/receiveTypesAction' },
        TypeAnswer: { action: 'Types/receiveTypeAction' },

        // Clients
        ClientSchemeAnswer: { action: 'Clients/receiveClientSchemeAction', preprocess: { jsonParse: false } },
        GetClientICD10CodesAnswer: { action: 'Clients/receiveClientICD10CodesAction' },

        // Rules
        AccountAnswer: { action: 'Account/receiveAccountAction' },  // Temporary

        // Account
        LoginAnswer: { action: 'Account/receiveLoginAction', preprocess: { oldVersion: true, jsonParse: ["data"], callback: [receiveLoginCallback] } },
        UserInfoAnswer: { action: 'Account/receiveUserInfoAction', preprocess: { oldVersion: true, jsonParse: ["data"], callback: [receiveUserInfoCallback] } },
        RefreshTokenAnswer: { action: 'Account/receiveRefreshTokenAction', preprocess: { oldVersion: true, jsonParse: ["data"], callback: [receiveRefreshTokenCallback] } },
        UserProjectsAnswer: { action: 'Account/receiveUserProjectsAction', preprocess: { callback: [receiveUserProjectsActionCallback] } },

        // Demo
        DemoModulesAnswer: { action: 'Demo/receiveDemoModulesAction', preprocess: { jsonParse: false } },
        DemoClientsAnswer: { action: 'Demo/receiveDemoClientsAction' },
        DemoClientAnswer: { action: 'Demo/receiveDemoClientAction' },
        DemoClientGuidelinesAnswer: { action: 'Demo/receiveDemoClientGuidelinesAction' },
        ChangeClientGuidelineStatusAnswer: { action: 'Demo/receiveChangeClientGuidelineStatusAction', preprocess: { callback: [receiveChangeClientGuidelineStatusCallback] } },
        ChangeGuidelineStatusAnswer: { action: 'Demo/receiveChangeGuidelineStatusAction', preprocess: { callback: [receiveChangeGuidelineStatusCallback] } },
        ProjectExecutionStatusAnswer: { action: 'Demo/receiveRunDemoAction', preprocess: { callback: [receiveDemoRunCallback] } },

        // ClinicalResearch
        ClinicalResearchesAnswer: { action: 'ClinicalResearch/receiveClinicalResearchesAction' },
        ClinicalResearchStatusUpdateAnswer: { action: 'ClinicalResearch/receiveUpdateClinicalResearchStatusAction' },


        // Help Center
        HelpAnswer: { action: 'HelpCenter/receiveHelpCenterDataAction', preprocess: { jsonParse: false } },


        // Questionnaire
        QuestionnaireAnswer: { action: 'Questionnaire/receiveQuestionnaireAction' },
        QuestionnaireStatisticsAnswer: { action: 'Questionnaire/receiveQuestionnaireStatisticsAction'},
        AddQuestionnaireAnswersAnswer: { action: 'Questionnaire/receiveAddQuestionnaireAnswersAction', preprocess: { callback: [receiveAddQuestionnaireAnswerCallback] } },
        OpenQuestionnaireAnswer: { action: 'Questionnaire/receiveOpenQuestionnaireAction', preprocess: { callback: [receiveOpenQuestionnaireCallback] } },
        AddOpenQuestionnaireAnswersAnswer: { action: 'Questionnaire/receiveAddOpenQuestionnaireAnswersAction', preprocess: { callback: [receiveAddOpenQuestionnaireAnswerCallback] } },

        // Statistics
        StatisticsProjectAnswer: { action: 'Statistics/receiveProjectStatisticsAction' },
        
        // Reports
        ReportResultsAnswer: { action: 'Reports/receiveReportsAction', preprocess: { callback: [receiveProjectStatisticsActionCallback] } },
        ReportRunActionAnswer: { action: 'Reports/receiveReportsRunAction' },

        // Clients
        GetClientDetailsAnswer: { action: 'Reports/receiveClientDetailsAction' },

        // Priorities
	    ClientsHealthPrioritiesAnswer: { action: 'Priorities/receiveClientsHealthPrioritiesAction' }
    }
}