import {useDispatch, useSelector} from 'react-redux';
import {useAccount} from './UseAccount';
import {getClientsHealthPrioritiesAction} from "../Redux/Slices/Priorities/Priorities.slice";
import {useState} from "react";

export const usePriorities = (): any => {

	const dispatch = useDispatch();
	const {HMSProjectId} = useAccount();

	const clientsPriorities = useSelector((state: any) => state.priorities.clientsPriorities);

	// States
	const [exportLoading, setExportLoading] = useState(false);

	// Fetch Demo Modules
	const fetchClientsHealthPriorities = (HMSProjectIdLocal: string | null = null, sorting: any = {}, filters: any[] = [], pageNumber: number = 1, pageSize: number = 25) => {
		let projectId = HMSProjectIdLocal ?? HMSProjectId;
		const pageNum = pageNumber-1 >= 0 ? pageNumber-1 : 0;
		if (projectId == null) return;

		dispatch(getClientsHealthPrioritiesAction([projectId, {
			"requestID": "r",
			"pageNumber": pageNum,
			"pageSize": pageSize,
			"sorting": sorting,
			"filterBy": filters,
			"projectUID": HMSProjectId,
		}]));
	};


	// Fetch Excel Priorities Data
	const fetchExcelPrioritiesData = async (HMSProjectId: null | string = null, accessToken: string, filters: any[] = []) => {
		try {
			setExportLoading(true);
			const response = await fetch(`https://api.lavaa.health/healthpriority`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					reportName: "",
					projectUID: HMSProjectId,
					token: accessToken,
					filters
				})
			});

			const fileNames = "Health_Priorities";

			if (response.ok) {
				const blob = await response.blob();
				if (blob.size > 0) {
					const url = window.URL.createObjectURL(blob);
					const a = document.createElement('a');
					a.style.display = 'none';
					a.href = url;

					const now = new Date();
					const year = now.getFullYear();
					const month = String(now.getMonth() + 1).padStart(2, '0'); // +1 because January is 0
					const day = String(now.getDate()).padStart(2, '0');
					const hours = String(now.getHours()).padStart(2, '0');
					const minutes = String(now.getMinutes()).padStart(2, '0');
					const seconds = String(now.getSeconds()).padStart(2, '0');

					a.download = `${fileNames}_${year}-${month}-${day}_${hours}-${minutes}-${seconds}.xlsx`;
					document.body.appendChild(a);
					a.click();
					window.URL.revokeObjectURL(url);
				}
			}
			setExportLoading(false);
		} catch (err) {
		}
	};
	

	return {
		exportLoading,
		clientsPriorities,
		fetchClientsHealthPriorities, fetchExcelPrioritiesData
	};
}