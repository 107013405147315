import {ChangeEvent, useEffect, useRef, useState} from "react";

export type ServerSearchFilterMethodsType = {
    resetFilter: () => void;
};

export const ServerSearchFilter = (props: any) => {
    const { model, onChange, column, tableId } = props;
    const colId = column.colId;

    // States
    const [value, setValue] = useState((model && model.filter) || '');
    const valueRef = useRef<string>(value);
    const timerId = useRef<any>(null);

    useEffect(() => {
        window.addEventListener('ResetDataTableFilter', resetFilter, {passive: true});

        return () => {
            window.removeEventListener('ResetDataTableFilter', resetFilter);
        }
    }, []);
    
    const onInput = ({ target: { value: newValue } }: ChangeEvent<HTMLInputElement>) => {
        setValue(newValue);
        valueRef.current = newValue;

        // Make a delay before sending changes
        if(timerId.current) clearTimeout(timerId.current);
        timerId.current = setTimeout(sendChanges, 300);
    };

    // Temporary function to reset filter
    // Because we can't send data to ag-grid component directly
    function resetFilter(e: any) {
        if(!e.detail || !e.detail.tableId || e.detail.tableId != tableId) return;

        setValue('');
        valueRef.current = '';
        sendChanges();
    }

    function sendChanges() {
        const data = {
            [colId]: {
                filterType: "text",
                type: "contains",
                filter: valueRef.current
            }
        }
        if(onChange) onChange("filterChanged", data);
    }

    return (
        <div className="ag-floating-filter-body" role="presentation">
            <div className="ag-floating-filter-input" role="presentation">
                <div role="presentation" className="ag-labeled ag-label-align-left ag-text-field ag-input-field">
                    <div className="ag-wrapper ag-input-wrapper ag-text-field-input-wrapper" role="presentation">
                        <input value={value} onInput={onInput} className="ag-input-field-input ag-text-field-input" type="text" autoComplete="off" />
                    </div>
                </div>
            </div>
        </div>
    );
};